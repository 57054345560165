<template>
	<div>
		<div class="mb-4 flex space-x-3">
			<NuxtImg :src="contactPersonImg" class="h-12 w-12 rounded-full object-cover" />
			<div>
				<div class="font-bold mb-1">
					{{ contactPerson.displayName }}
				</div>
				<p v-if="position && position !== 'other'" class="text-xs mb-0">
					{{ position }}
				</p>
			</div>
		</div>
		<p v-if="slogan" class="text-sm italic mb-0">{{ slogan }}</p>
	</div>
</template>
<script lang="ts">
import type { IAPILoginUser, IAPIOwnerSnippet } from '@hokify/common';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'JobContactPerson',
	computed: {
		slogan(): string | undefined {
			return (
				(this.contactPerson as IAPIOwnerSnippet)?.recruiter?.slogan ||
				(this.contactPerson as IAPILoginUser)?.recruiterProfile?.slogan
			);
		},
		position(): string | undefined {
			return (
				(this.contactPerson as IAPIOwnerSnippet)?.recruiter?.position ||
				(this.contactPerson as IAPILoginUser)?.recruiterProfile?.position
			);
		},
		contactPersonImg(): string {
			if (this.contactPerson?.images?.small) {
				return this.contactPerson.images.small;
			}
			return '/shared-components-nuxt3/images/unknown_user.png';
		}
	},
	props: {
		contactPerson: { type: Object, required: true }
	}
});
</script>
