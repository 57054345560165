<template>
	<div class="w-full relative" :class="headerClasses">
		<div style="padding-bottom: 65%">
			<HokBgImg
				:source="job.images"
				class="absolute inset-0 bg-color-white"
				:bg-cover="$isMobile.any"
				:class="{ 'bg-center': !$isMobile.any }"
			>
			</HokBgImg>
		</div>
		<div
			v-if="activeSourcingMode && $isMobile.any"
			class="absolute flex inset-0 justify-center top-[72%]"
		>
			<div
				class="flex bg-color-coral items-center justify-center opacity-90 rounded-lg w-[172px] h-[35px] text-color-white text-sm py-2 px-3 leading-normal"
			>
				<HokIcon class="mr-1 mt-[1px]" :size="4" color="white" name="icon:flash" />
				<strong>An dir interessiert</strong>
			</div>
		</div>
		<div
			v-if="companyLogo"
			class="absolute"
			:class="$isMobile.any ? 'flex inset-0 justify-center top-[90%]' : 'left-2 bottom-2'"
		>
			<div class="w-16 h-16 relative border border-color-grey-light bg-color-white">
				<NuxtImg
					:src="companyLogo"
					alt="Firmenlogo"
					class="rounded-lg object-contain h-[62px] w-[62px]"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import type { IAPIJobForUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'JobHeaderImage',
	computed: {
		companyLogo() {
			return this.job?.company?.logos?.small || '';
		},
		headerClasses() {
			if (this.$isMobile.any) {
				return '';
			}
			if (!this.$isMobile.any && this.showEducation) {
				return 'mb-3';
			}
			return 'mb-6';
		}
	},
	props: {
		job: { type: Object as PropType<IAPIJobForUser>, required: true },
		showIcons: { type: Boolean, default: false },
		activeSourcingMode: { type: Boolean, default: false },
		showEducation: { type: Boolean, default: false },
		jobRegion: { type: String, default: 'at' }
	}
});
</script>
