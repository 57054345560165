<template>
	<div>
		<div v-if="showSharingButton" class="flex flex-1 justify-end">
			<div
				id="sharing-icon-wrapper"
				class="w-10 h-10 flex items-center justify-center cursor-pointer"
				@click="$emit('reveal-modal')"
			>
				<HokIcon
					id="sharing-icon"
					vertical-align="align-bottom"
					:size="5"
					name="icon:share"
					:color="color"
					class="inline"
				/>
			</div>
		</div>
		<HokModal
			width="366px"
			click-to-close
			show-close-button
			name="sharingmodal"
			@closed="handleCloseModal"
		>
			<h6 class="border-b-[1px] border-color-grey-light pl-4 pb-4 h-9 mb-0">
				<HokIcon
					v-if="feedbackFormOpened"
					:size="5"
					name="icon:arrow-left"
					color="grey"
					pointer
					class="absolute left-4"
					@click="feedbackFormOpened = false"
				/>
				<span v-else>{{
					sharingInfo.targetType === 'company' ? 'Unternehmensprofil teilen' : 'Inserat teilen'
				}}</span>
			</h6>
			<section class="relative pt-8">
				<div class="px-4 pb-8 flex items-center">
					<p
						class="border border-color-grey-light rounded-lg mb-0 px-4 py-2 w-[298px]"
						:class="[
							revealFullLink ? 'break-words' : 'truncate',
							color === 'main' ? 'text-color-main' : 'text-color-main-business'
						]"
						@copy="copyLinkManually"
						@click="revealFullLink = true"
					>
						{{ sharingInfo.link }}
					</p>
					<CopyURLToClipboard
						:size="5"
						:color="color"
						class="pl-4"
						:full-path="sharingInfo.link"
						:inline-icon="false"
						@click="copyLinkViaButton"
					/>
				</div>
				<div class="flex flex-col space-y-4 pl-4 pb-4">
					<a
						v-for="option in sharingMode"
						:key="option.text"
						:href="option.link"
						target="_blank"
						class="flex items-center space-x-4 group cursor-pointer text-color-text"
						@click="triggerSharingOption(option.name)"
					>
						<div
							class="w-10 h-10 flex items-center justify-center rounded-full bg-color-grey-lightest group-hover:bg-color-grey-light"
						>
							<HokIcon :size="5" :name="option.icon" :color="color" class="inline" />
						</div>
						<span
							class="group-hover:underline"
							:class="
								color === 'main'
									? 'group-hover:text-color-main-hover'
									: 'group-hover:text-color-main-business-hover'
							"
							>{{ option.text }}</span
						>
					</a>
					<div
						v-if="sharingInfo.jobName && !websiteMode"
						class="flex items-center space-x-4 group cursor-pointer text-color-text"
						@click="copyButtonHTML"
					>
						<div
							class="w-10 h-10 flex items-center justify-center rounded-full bg-color-grey-lightest group-hover:bg-color-grey-light"
						>
							<HokIcon :size="5" name="icon:tag" color="main-business" class="inline" />
						</div>
						<span class="group-hover:underline group-hover:text-color-main-business-hover"
							>Button für Website kopieren</span
						>
					</div>
				</div>
				<p
					v-if="!websiteMode"
					class="italic text-right mb-0 pr-4 pb-3 pt-1 text-color-grey text-sm hover:underline hover:text-color-main-business-hover cursor-pointer"
					@click="feedbackFormOpened = true"
				>
					Feedback senden
				</p>
				<div
					v-show="feedbackFormOpened"
					class="absolute inset-0 bg-color-white flex flex-col rounded-lg z-50 px-4"
				>
					<p class="texl-lg font-bold my-3">Feedback geben</p>
					<FeedbackForm
						id="feedback-form"
						class="flex-1"
						question="Wie gefallen Ihnen die Teilen-Funktionen?"
						:target-data="{
							targetObjId: sharingInfo.targetObjId,
							targetType: sharingInfo.targetType,
							type: `share-${sharingInfo.targetType}`
						}"
						@send-feedback="sendFeedback($event)"
					/>
				</div>
			</section>
		</HokModal>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import CopyURLToClipboard from './CopyURLToClipboard.vue';
import FeedbackForm from './FeedbackForm.vue';
import { ISharingInfo } from '../helpers/sharing-modal';

export default defineComponent({
	name: 'SharingModal',
	components: { CopyURLToClipboard, FeedbackForm },
	emits: ['sharing-modal-tracking', 'send-feedback', 'reveal-modal'],
	beforeUnmount() {
		// make sure to hide modal so it doesn't remain open when navigating back from insidepage
		this.$modal.hide('sharingmodal');
	},
	data() {
		return {
			feedbackFormOpened: false,
			sharingOptionUsed: false,
			revealFullLink: false
		};
	},
	computed: {
		sharingOptions(): { name: string; icon: string; text: string; link: string }[] {
			return [
				{
					name: 'email',
					icon: 'icon:letter-empty',
					text: 'Per E-Mail verschicken',
					link: `mailto:?subject=${
						this.sharingInfo.jobName
							? `Job-Empfehlung für dich: ${this.sharingInfo.jobName}`
							: `Schau dir dieses Unternehmen an${
									this.sharingInfo.companyName ? `: ${this.sharingInfo.companyName}` : ''
								}`
					}&body=${this.mailBody}`
				},
				{
					name: 'linkedin',
					icon: 'icon:linkedin-2',
					text: 'Auf LinkedIn teilen',
					link: `https://www.linkedin.com/shareArticle?url=${this.sharingInfo.link}`
				},
				{
					name: 'facebook',
					icon: 'icon:fb',
					text: 'Auf Facebook teilen',
					link: `https://www.facebook.com/sharer.php?u=${this.sharingInfo.link}`
				},
				{
					name: 'whatsapp',
					icon: 'icon:whatsapp',
					text: 'Per WhatsApp verschicken',
					link: `${this.$isMobile.any ? 'whatsapp://' : 'https://web.whatsapp.com/'}send?text=${
						this.sharingInfo.link
					}`
				}
			];
		},
		sharingOptionsWebsite(): { name: string; icon: string; text: string; link: string }[] {
			return [
				{
					name: 'whatsapp',
					icon: 'icon:whatsapp',
					text: 'Per WhatsApp verschicken',
					link: `${this.$isMobile.any ? 'whatsapp://' : 'https://web.whatsapp.com/'}send?text=${
						this.sharingInfo.link
					}`
				},
				{
					name: 'email',
					icon: 'icon:letter-empty',
					text: 'Per E-Mail verschicken',
					link: `mailto:?subject=${
						this.sharingInfo.jobName
							? `Job-Empfehlung für dich: ${this.sharingInfo.jobName}`
							: `Schau dir dieses Unternehmen an${
									this.sharingInfo.companyName ? `: ${this.sharingInfo.companyName}` : ''
								}`
					}&body=${this.mailBody}`
				},
				{
					name: 'linkedin',
					icon: 'icon:linkedin-2',
					text: 'Auf LinkedIn teilen',
					link: `https://www.linkedin.com/shareArticle?url=${this.sharingInfo.link}`
				},
				{
					name: 'facebook',
					icon: 'icon:fb',
					text: 'Auf Facebook teilen',
					link: `https://www.facebook.com/sharer.php?u=${this.sharingInfo.link}`
				}
			];
		},
		sharingMode(): { name: string; icon: string; text: string; link: string }[] {
			if (this.websiteMode) {
				return this.sharingOptionsWebsite;
			}
			return this.sharingOptions;
		},
		buttonHtml(): string {
			return `<a href="${this.sharingInfo.link}" target="_blank" style="padding:7px 17px!important;border-radius:4px!important;color:#fff!important;background-color:#0fb1af!important;text-decoration:none!important;font-weight:bold!important;" >Bewerbung starten</a>`;
		},
		mailBody(): string {
			return encodeURIComponent(
				`${this.sharingInfo.salutation}\n${this.sharingInfo.jobName || ''}\n${
					this.sharingInfo.companyName
				}${
					this.sharingInfo.jobName
						? '\nDetails: '
						: `${this.sharingInfo.details ? '\nStandort: ' : ''}`
				}${this.sharingInfo.details}\nLink: ${this.sharingInfo.link}`
			);
		},
		openModalOnMount(): boolean {
			return !!(
				this.$route.query?.openSharingModal && this.$route.query?.openSharingModal === 'true'
			);
		}
	},
	mounted() {
		if (this.openModalOnMount) {
			this.$modal.show('sharingmodal');
		}
	},
	methods: {
		triggerSharingOption(optionName: string) {
			this.$emit('sharing-modal-tracking', optionName);
			this.sharingOptionUsed = true;
		},
		copyLinkManually() {
			const selection = document.getSelection();
			if (selection && selection.toString().includes(this.sharingInfo.link)) {
				this.sharingOptionUsed = true;
				this.$emit('sharing-modal-tracking', 'copied-link-manually');
			}
		},
		copyLinkViaButton() {
			this.sharingOptionUsed = true;
			this.$emit('sharing-modal-tracking', 'copied-link-via-button');
		},
		copyButtonHTML() {
			const el = document.createElement('textarea');
			el.value = this.buttonHtml;
			el.setAttribute('readonly', '');
			el.style.cssText = 'position:fixed;pointer-events:none;z-index:-9999;opacity:0;';

			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			this.$snack.show({
				text: 'HTML-Button in Zwischenablage kopiert.'
			});
			this.$emit('sharing-modal-tracking', 'copied-html-button');
			this.sharingOptionUsed = true;
		},
		handleCloseModal(hideModal = true) {
			if (hideModal) {
				this.$emit(
					'sharing-modal-tracking',
					`modal-closed-${this.sharingOptionUsed ? 'used' : 'unused'}`
				);
				this.$modal.hide('sharingmodal');
				this.sharingOptionUsed = false;
			}
			this.feedbackFormOpened = false;
			this.revealFullLink = false;
		},
		sendFeedback(feedback) {
			this.$emit('send-feedback', feedback);
			this.feedbackFormOpened = false;
		}
	},
	props: {
		showSharingButton: { type: Boolean, default: true },
		title: { type: String, default: 'hokify.at - die größte mobile Job-Plattform' },
		sharingInfo: { type: Object as PropType<ISharingInfo>, default: () => {} },
		color: {
			type: String,
			default: 'main',
			validator: (value: string) => ['main', 'main-business'].includes(value)
		},
		websiteMode: { type: Boolean, default: false }
	}
});
</script>

<style lang="scss" scoped>
::v-deep(.v--modal-background-click > .v--modal-box) {
	@apply px-0 py-4;
}

::v-deep(.v--modal-box > span > svg) {
	@apply text-color-grey w-5 h-5 m-4;
}

::v-deep(#feedback-form textarea) {
	height: 100%;
}
</style>
