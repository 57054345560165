<template>
	<div class="relative" :class="{ 'inline-block': inlineIcon }">
		<span
			v-if="toolTip && !$isMobile.any && openTooltip"
			class="absolute max-w-max w-[288px] shadow-md text-color-white text-left rounded-b-lg text-sm p-2 bg-color-main-business font-normal z-30 top-5"
			:class="inlineIcon ? 'rounded-tr-lg left-4' : 'rounded-tl-lg right-4'"
		>
			{{ toolTip }}
		</span>
		<HokIcon
			:class="{ 'ml-1 -mb-0.5': inlineIcon }"
			:name="copied ? 'icon:checkmark-2' : 'icon:copy'"
			:color="color"
			:size="size"
			pointer
			@click="copyToClipboard"
			@mouseover="openTooltip = true"
			@mouseleave="openTooltip = false"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';

export default defineComponent({
	name: 'CopyURLToClipboard',
	components: { HokIcon },
	data() {
		return {
			openTooltip: false,
			copied: false
		};
	},
	computed: {
		host(): string | undefined {
			return this.$nuxt?.$userRootStore?.host;
		}
	},
	methods: {
		copyToClipboard() {
			this.copied = true;
			setTimeout(() => {
				this.copied = false;
			}, 3000);
			if (this.pageElement) {
				this.$trackCompanyEvent?.('click_copy_to_clipboard', { pageElement: this.pageElement });
			}
			if (this.fullPath) {
				this.copyStringToClipboard(this.fullPath);
			} else {
				const url = new URL(this.host || '', 'https://hokify.com');
				if (this.pathName) {
					url.pathname = this.pathName;
				} else {
					throw new Error('No valid URL');
				}
				this.copyStringToClipboard(url.toString());
			}
			this.$snack.show({
				text: this.message
			});
		},
		copyStringToClipboard(str: string) {
			const el = document.createElement('textarea');
			el.value = str;
			el.setAttribute('readonly', '');
			el.style.cssText = 'position:fixed;pointer-events:none;z-index:-9999;opacity:0;';

			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		}
	},
	props: {
		toolTip: { type: String, default: '' },
		message: { type: String, default: 'Link in die Zwischenablage kopiert' },
		pathName: { type: String, required: false, default: '' },
		fullPath: { type: String, required: false, default: '' },
		pageElement: { type: String, required: false },
		inlineIcon: { type: Boolean, default: true },
		size: {
			type: Number,
			default: 4
		},
		color: {
			type: String,
			default: 'default'
		}
	}
});
</script>
