<template>
	<div class="relative pb-5" itemscope itemtype="http://schema.org/JobPosting">
		<meta itemprop="directApply" content="True" />
		<div
			id="overflow-container"
			class="relative"
			:class="!singleJobView ? 'h-[90vh] overflow-y-auto' : ''"
		>
			<!-- Header-section Desktop -->
			<div
				v-if="!$isMobile.any && !singleJobView && showBar"
				class="absolute flex bg-color-white shadow-md w-full sticky top-0 py-3 px-5 z-50 items-center"
			>
				<div class="mr-5" :class="region === 'de' ? 'w-1/2' : 'w-3/5'">
					<h1
						class="text-left text-xl mb-1 leading-none text-color-main w-full truncate"
						itemprop="title"
					>
						{{ job.name }}
					</h1>
					<div>
						<h2
							v-if="job.company && job.company.name"
							class="text-base font-bold text-color-text mb-0 mr-2 w-full truncate"
						>
							{{ job.company.name }}
						</h2>
						<div
							v-if="
								(job.addressText && job.addressText.length > 0) ||
								(job.location && (job.location.city || job.location.name))
							"
							class="flex items-center text-sm"
						>
							<HokIcon name="icon:pin-2" :size="4" color="text" class="-ml-1" />
							<address
								v-if="
									job.addressText || (job.location && (job.location.city || job.location.district))
								"
								itemprop="address"
								itemscope
								itemtype="http://schema.org/PostalAddress"
								class="not-italic"
							>
								<span v-if="job.addressText && job.addressText.length > 0">
									{{ job.addressText }}
									<meta
										v-if="job.location && job.location.street"
										itemprop="streetAddress"
										:content="job.location && job.location.street"
									/>
									<meta
										v-if="job.location && job.location.code"
										itemprop="postalCode"
										:content="job.location.code"
									/>
									<meta itemprop="addressLocality" :content="job.location && job.location.city" />
								</span>
								<template v-else-if="job.location">
									<span v-if="job.location.street" itemprop="streetAddress">{{
										truncateFct(job.location.street, 60)
									}}</span
									><template v-if="job.location.street">, </template>
									<span v-if="job.location.code" itemprop="postalCode">{{
										job.location.code
									}}</span>
									<HokLink
										v-if="additionalJobData && additionalJobData.cityCanonical"
										:to="additionalJobData.cityCanonical"
										class="text-color-text hover:underline"
									>
										<span itemprop="addressLocality">
											{{ job.location.city || job.location.district }}
										</span>
									</HokLink>
									<span v-else itemprop="addressLocality">{{
										job.location.city || job.location.district
									}}</span>
								</template>
								<meta
									v-if="job.location && job.location.countryCode"
									itemprop="addressCountry"
									:content="job.location.countryCode.toUpperCase()"
								/>
								<meta v-if="addressRegion" itemprop="addressRegion" :content="addressRegion" />
							</address>
							<template v-else>{{ job.location.name }}</template>
						</div>
					</div>
				</div>
				<HokButton
					v-if="job && job.activeSourcingConversationId && isInContactWithCompany"
					:to="`/match/${job.activeSourcingConversationId}`"
					fullwidth="mobile"
					:link-type="cordova ? 'internal' : 'external'"
					color="main"
					class="ml-auto"
				>
					Zum Chat
				</HokButton>
				<HokButton
					v-else-if="activeSourcingMode"
					fullwidth="mobile"
					color="main"
					class="ml-auto"
					@click="$emit('acts-invite', 'accepted')"
				>
					Kontakt aufnehmen
				</HokButton>
				<div
					v-else
					class="flex justify-center items-center"
					:class="region === 'de' ? 'w-1/2' : 'w-2/5'"
				>
					<HokIcon
						v-if="region === 'de'"
						color="main"
						name="icon:share"
						class="mr-4 cursor-pointer"
						@click="shareJob"
					/>
					<HokButton
						outline
						color="main"
						size="small"
						fullwidth="always"
						class="flex mr-4"
						@click="$emit('save-job', job)"
					>
						Job&nbsp;{{ jobIsSaved ? 'entfernen' : 'speichern' }}
					</HokButton>
					<HokButton
						:to="`${linkToApply}?cs=job-page-cta`"
						invisible
						:link-type="cordova ? 'internal' : 'external'"
						size="small"
						fullwidth="always"
						color="main"
						>Jetzt bewerben
					</HokButton>
				</div>
			</div>
			<section
				v-if="!$isMobile.any"
				id="jobdetail-header-desktop"
				class="relative pt-6 flex justify-between shadow-lg"
				:class="[
					{ 'bg-color-grey-lightest': singleJobView },
					job.activeSourcingContactStatus && !$isMobile.any ? 'mb-8' : 'mb-5'
				]"
			>
				<div class="w-7/12 mx-5">
					<div :class="{ 'w-full': !singleJobView }">
						<h1
							v-if="singleJobView"
							class="text-left text-xl mb-1 leading-none text-color-main"
							itemprop="title"
						>
							{{ job.name }}
						</h1>
						<h3
							v-else
							class="text-left text-xl mb-1 leading-none text-color-main font-bold"
							itemprop="title"
							data-cy="jobDetailJobName"
						>
							{{ job.name }}
						</h3>
						<h2
							v-if="job.company && job.company.name && singleJobView"
							class="text-base font-bold text-color-text w-full"
							itemprop="hiringOrganization"
							itemscope
							itemtype="http://schema.org/Organization"
							data-cy="companyName"
						>
							<HokLink
								v-if="job.company.alias && !isPwa"
								:to="`/c/${job.company.alias}`"
								link-type="internal"
								class="text-color-text hover:underline"
							>
								{{ job.company.name }}
							</HokLink>
							<template v-else>
								{{ job.company.name }}
							</template>
							<meta itemprop="name" :content="job.company.name" />
							<meta
								v-if="additionalSettings && additionalSettings.squareCompanyLogo"
								itemprop="logo"
								:content="additionalSettings.squareCompanyLogo"
							/>
						</h2>
						<span
							v-else-if="job.company && job.company.name"
							class="text-base font-bold text-color-text"
							itemprop="hiringOrganization"
							itemscope
							itemtype="http://schema.org/Organization"
							data-cy="companyName"
						>
							<HokLink
								v-if="job.company.alias && !isPwa"
								:to="`/c/${job.company.alias}`"
								link-type="internal"
								class="text-color-text hover:underline"
							>
								{{ job.company.name }}
							</HokLink>
							<template v-else>
								{{ job.company.name }}
							</template>
							<meta itemprop="name" :content="job.company.name" />
							<meta
								v-if="additionalSettings && additionalSettings.squareCompanyLogo"
								itemprop="logo"
								:content="additionalSettings.squareCompanyLogo"
							/>
						</span>
					</div>

					<JobFacts
						:job="job"
						:additional-job-data="additionalJobData"
						:is-pwa="isPwa"
						class="w-full flex flex-col mb-6 text-color-text"
						@go-to-gnc="scrollToGnc"
					/>
					<template v-if="showApplyButton">
						<template v-if="job && job.activeSourcingConversationId && isInContactWithCompany">
							<HokButton
								:to="`/match/${job.activeSourcingConversationId}`"
								fullwidth="mobile"
								:link-type="cordova ? 'internal' : 'external'"
								color="main"
								class="mb-4"
							>
								Zum Chat
							</HokButton>
							<p class="text-color-grey-medium text-sm mb-5">
								(Du hast dieses Unternehmen bereits kontaktiert)
							</p>
						</template>
						<template v-else-if="activeSourcingMode || hasRejectedContactRequest">
							<HokButton class="mb-4 mr-3" color="main" @click="$emit('acts-invite', 'accepted')">
								Kontakt aufnehmen
							</HokButton>
							<HokButton
								v-if="!isATSJob"
								outline
								class="mb-4"
								color="main"
								@click="$emit('acts-invite', 'more-info')"
							>
								Mehr Infos
							</HokButton>
							<div class="flex items-center text-color-main text-sm mb-5">
								<HokButton
									id="no-interest-desktop"
									is-text
									font-weight="normal"
									:color="hasRejectedContactRequest ? 'grey' : 'main'"
									class="mr-6"
									:class="{ 'not-allowed': hasRejectedContactRequest }"
									@click="handleNoInterest"
								>
									<HokIcon
										name="icon:close-slim"
										:color="hasRejectedContactRequest ? 'grey' : 'main'"
										:size="3"
										class="mr-2"
									/>
									Kein Interesse
								</HokButton>
							</div>
						</template>
						<HokButton
							v-else
							:to="`${linkToApply}?cs=job-page-cta`"
							invisible
							:link-type="cordova ? 'internal' : 'external'"
							data-cy="applyButton"
							color="main"
							class="mb-4"
						>
							Jetzt bewerben
						</HokButton>
					</template>
					<div v-if="showExtraFunctionality" class="flex items-center text-color-main text-sm mb-5">
						<HokButton
							color="main"
							is-text
							font-weight="normal"
							class="mr-6"
							data-cy="sendJobHeader"
							@click="shareJob"
						>
							<HokIcon name="icon:share" color="main" :size="4" class="mr-1" />
							Job teilen
						</HokButton>
						<HokButton
							v-if="showSaveButton"
							color="main"
							is-text
							font-weight="normal"
							class="mr-1"
							@click="$emit('save-job', job)"
						>
							<template v-if="jobIsSaved"
								><HokIcon name="icon:bookmark" color="main" :size="4" class="mr-1" />Job
								entfernen</template
							>
							<template v-else>
								<HokIcon name="icon:bookmark-outline" color="main" :size="4" class="mr-1" />Job
								speichern
							</template>
						</HokButton>
						<HokButton
							v-if="showDeleteButton"
							is-text
							font-weight="normal"
							class="flex justify-center"
							color="main"
							@click="$emit('delete-job')"
						>
							<HokIcon name="icon:bookmark" color="main" :size="4" />
							Job entfernen
						</HokButton>
					</div>
				</div>
				<div class="mr-5 w-5/12">
					<JobHeaderImage
						v-if="job.images"
						:job="job"
						:show-icons="false"
						:show-education="showEducation"
						:job-region="jobRegion"
					/>
					<div
						v-if="showEducation && !$isMobile.any"
						class="flex space-x-2 items-center text-start mb-3"
					>
						<HokIcon name="icon:checkmark-gradient-border"></HokIcon>
						<span class="text-color-text text-sm"
							>Bewirb dich mit deinem hokify Profil in 3 Minuten!</span
						>
					</div>
				</div>
			</section>
			<!-- Header-section Mobile 				-->
			<section v-else class="relative shadow-lg mb-6">
				<JobHeaderImage
					v-if="job.images"
					:active-sourcing-mode="activeSourcingMode"
					:job="job"
					:show-icons="false"
				/>
				<div class="relative h-12">
					<div v-if="showExtraFunctionality" class="flex absolute top-2 right-0">
						<HokIcon
							data-cy="sendJobHeader"
							name="icon:share"
							color="main"
							:size="6"
							class="mr-6"
							@click="shareJob"
						/>
						<template v-if="showSaveButton">
							<HokIcon
								v-if="jobIsSaved"
								name="icon:bookmark"
								color="main"
								:size="6"
								class="mr-4"
								@click="$emit('save-job', job)"
							/>
							<HokIcon
								v-else
								name="icon:bookmark-outline"
								color="main"
								:size="6"
								class="mr-4"
								@click="$emit('save-job', job)"
							/>
						</template>
					</div>
				</div>
				<div class="mx-5">
					<h1 class="text-left text-xl mb-2 tracking-wide leading-none" itemprop="title">
						{{ job.name }}
					</h1>
					<h2
						v-if="job.company && job.company.name"
						class="text-base font-bold text-color-text tracking-wide"
						itemprop="hiringOrganization"
						itemscope
						itemtype="http://schema.org/Organization"
						data-cy="companyName"
					>
						<HokLink
							v-if="job.company.alias && !isPwa"
							:to="`/c/${job.company.alias}`"
							link-type="internal"
							class="text-color-text hover:underline"
						>
							{{ job.company.name }}
						</HokLink>
						<template v-else>
							{{ job.company.name }}
						</template>
						<meta itemprop="name" :content="job.company.name" />
						<meta
							v-if="additionalSettings && additionalSettings.squareCompanyLogo"
							itemprop="logo"
							:content="additionalSettings.squareCompanyLogo"
						/>
					</h2>

					<JobFacts
						:job="job"
						:additional-job-data="additionalJobData"
						:is-pwa="isPwa"
						class="w-full flex flex-col mb-4"
						@go-to-gnc="scrollToGnc"
					/>
				</div>
				<div class="text-center mx-5">
					<template v-if="showApplyButton">
						<template v-if="job && job.activeSourcingConversationId && isInContactWithCompany">
							<HokButton
								id="jobdetail-chat-mobile"
								:to="`/match/${job.activeSourcingConversationId}`"
								fullwidth="mobile"
								:link-type="cordova ? 'internal' : 'external'"
								color="main"
								class="mb-4"
							>
								Zum Chat
							</HokButton>
							<p class="text-color-grey-medium text-sm mb-2">
								(Du hast dieses Unternehmen bereits kontaktiert)
							</p>
						</template>
						<template v-else-if="activeSourcingMode || hasRejectedContactRequest">
							<HokButton
								id="jobdetail-contact-mobile"
								fullwidth="always"
								class="mb-3"
								color="main"
								@click="$emit('acts-invite', 'accepted')"
							>
								Kontakt aufnehmen
							</HokButton>
							<div class="flex text-color-main justify-between pb-6">
								<HokButton
									v-if="!isATSJob"
									outline
									fullwidth="always"
									size="small"
									color="main"
									class="flex mr-1"
									@click="$emit('acts-invite', 'more-info')"
									>Mehr Infos
								</HokButton>
								<HokButton
									outline
									fullwidth="always"
									:size="isATSJob ? 'medium' : 'small'"
									:color="hasRejectedContactRequest ? 'grey' : 'main'"
									class="flex"
									:class="[{ 'ml-1': !isATSJob }]"
									@click="handleNoInterest"
								>
									<HokIcon
										name="icon:close-slim"
										:color="hasRejectedContactRequest ? 'grey' : 'main'"
										:size="3"
										class="mr-2"
									/>
									Kein Interesse
								</HokButton>
							</div>
						</template>
						<HokButton
							v-else
							id="jobdetail-apply-mobile"
							:to="`${linkToApply}?cs=job-page-cta`"
							invisible
							fullwidth="always"
							:link-type="cordova ? 'internal' : 'external'"
							data-cy="applyButton"
							:class="showEducation && jobRegion === 'de' ? 'mb-5' : 'mb-6'"
							color="main"
						>
							Jetzt bewerben
						</HokButton>
					</template>
					<div
						v-if="showEducation && jobRegion === 'de'"
						class="flex space-x-3 items-center text-start pb-4"
					>
						<HokIcon name="icon:checkmark-gradient-border"></HokIcon>
						<span class="text-color-text text-sm"
							>Bewirb dich mit deinem hokify Profil in <br v-if="$isMobile.any" />3 Minuten!</span
						>
					</div>
				</div>
			</section>
			<!-- Content-->
			<section class="relative">
				<div class="flex-none">
					<div
						v-if="hasRequiredFields && !activeSourcingMode"
						class="mx-5 pb-5 mb-5 border-b border-color-grey-light"
					>
						<h4 class="text-color-text text-xl">Ausbildung und Erfahrung</h4>
						<LazyJobRequiredFields
							class="pl-4"
							:required-fields="job.requiredFields"
							styling="rqf-wrapper-no-line"
						/>
					</div>
					<div
						v-if="!isMatchView && job.activeSourcingContactStatus"
						class="pb-3 mb-8 border-b border-color-grey-light"
						:class="$isMobile.any ? 'mx-5' : 'ml-5 mr-2'"
					>
						<div
							class="bg-color-coral rounded-xl p-6 mb-8"
							:class="$isMobile.any ? 'w-full' : 'w-7/12 flex'"
						>
							<div
								v-if="companyLogo"
								class="flex mr-3 w-1/2"
								:class="$isMobile.any ? 'mb-3' : 'items-center'"
							>
								<NuxtImg :src="userImg" class="h-10 w-10 rounded-full object-cover" />
								<NuxtImg
									:src="companyLogo"
									size="small"
									class="-ml-1 h-10 w-10 rounded-full object-conver"
								/>
							</div>

							<p class="mb-0 text-color-white">
								<strong>{{ job.company.name }} will dich kennenlernen!</strong><br />
								Das Unternehmen hat dich kontaktiert, weil es Interesse an dir hat. Nutze deinen
								Vorteil und
								<span class="underline hover:cursor-pointer" @click="handleClickContact"
									>nimm Kontakt auf!
								</span>
							</p>
						</div>
						<!--TODO @lbrcs: remove revealfulltext logic again as soon as we added character limit to active sourcing contact-->
						<div v-if="personalCompanyText" :class="$isMobile.any ? 'w-full' : 'w-7/12 mb-5'">
							<h4 class="text-color-text text-xl">Deine Nachricht von {{ job.company.name }}</h4>
							<p :class="{ 'mb-0': $isMobile.any && personalCompanyText.length > 400 }">
								{{
									truncateFct(
										personalCompanyText,
										revealFullText || !$isMobile.any ? personalCompanyText.length : 400
									)
								}}
							</p>
						</div>
						<HokButton
							v-if="$isMobile.any && personalCompanyText.length > 400"
							class="mb-5"
							is-text
							size="small"
							color="main"
							font-weight="light"
							@click="revealFullText = !revealFullText"
							>{{ revealFullText ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
						</HokButton>
					</div>
					<div
						v-else-if="job.questions && job.questions.length > 0"
						class="mx-5 pb-5 mb-5 border-b border-color-grey-light"
					>
						<h4 class="text-color-text text-xl">Das solltest du mitbringen</h4>
						<dl
							v-if="
								(requiredQuestions && requiredQuestions.length) ||
								(requiredYesNoQuestions.length &&
									requiredYesNoQuestions.some(question => question.shortquestion))
							"
							class="facts mb-4 pl-4"
						>
							<dt class="mb-2"><strong>Erforderlich</strong> für diesen Job</dt>
							<template v-if="requiredQuestions && requiredQuestions.length">
								<div
									v-for="question in requiredQuestions.slice(0, 5)"
									:key="question._id"
									class="flex"
								>
									<HokIcon :key="question._id" name="icon:checkmark" :size="4" />
									<dd v-if="question && question.question" :key="question._id" class="mx-1">
										{{ question.question }}
									</dd>
								</div>
							</template>
							<template v-if="requiredYesNoQuestions.length">
								<div v-for="question in requiredYesNoQuestions" :key="question.id">
									<div v-if="question && question.shortquestion" class="flex">
										<HokIcon :key="question.id" name="icon:checkmark" :size="4" />
										<dd class="mx-1">
											{{ question.shortquestion }}
										</dd>
									</div>
								</div>
							</template>
						</dl>
						<dl
							v-if="
								(optionalQuestions && optionalQuestions.length) ||
								(optionalYesNoQuestions.length &&
									optionalYesNoQuestions.some(question => question.shortquestion))
							"
							class="facts mb-4 pl-4"
						>
							<dt class="mb-2"><strong>Von Vorteil</strong> für diesen Job</dt>
							<template v-if="optionalQuestions && optionalQuestions.length">
								<div v-for="question in optionalQuestions.slice(0, 5)" :key="question._id">
									<div v-if="question && question.question" class="flex">
										<HokIcon :key="question._id" name="icon:checkmark" :size="4" />
										<dd class="mx-1">
											{{ question.question }}
										</dd>
									</div>
								</div>
							</template>
							<template v-if="optionalYesNoQuestions.length">
								<div v-for="question in optionalYesNoQuestions" :key="question.id">
									<div v-if="question && question.shortquestion" class="flex">
										<HokIcon :key="question.id" name="icon:checkmark" :size="4" />
										<dd :key="question.id" class="mx-1">
											{{ question.shortquestion }}
										</dd>
									</div>
								</div>
							</template>
						</dl>
					</div>
					<div v-if="job.description" class="mx-5 pb-5 mb-5">
						<h4 class="text-color-text text-xl">Über den Job:</h4>
						<BeautifyText
							:text="job.description"
							:link-to-apply="`${linkToApply}?cs=job-page-text`"
							itemprop="description"
						/>
						<div v-if="contactPerson" class="w-full md:w-7/12 mb-5">
							<h4 class="text-color-text text-xl">
								{{ contactPersonText }}
							</h4>
							<LazyJobContactPerson :contact-person="contactPerson" />
						</div>
						<div class="flex flex-col items-start">
							<HokButton
								data-cy="sendJobLinkDescription"
								is-text
								font-weight="normal"
								color="main"
								class="mb-3"
								@click="shareJob"
							>
								<HokIcon name="icon:share" :size="5" color="main" class="mr-2" />
								Job teilen
							</HokButton>
							<HokButton
								v-if="showSaveButton"
								is-text
								font-weight="normal"
								color="main"
								@click="$emit('save-job', job)"
							>
								<template v-if="jobIsSaved"
									><HokIcon name="icon:bookmark" color="main" :size="5" class="mr-2" />Job
									entfernen</template
								>
								<template v-else>
									<HokIcon name="icon:bookmark-outline" color="main" :size="5" class="mr-2" />Job
									speichern
								</template>
							</HokButton>
							<HokButton
								v-if="showDeleteButton"
								is-text
								font-weight="normal"
								color="main"
								@click="$emit('delete-job')"
							>
								<HokIcon name="icon:bookmark" color="main" :size="5" class="mr-3" />
								Job entfernen
							</HokButton>
						</div>
					</div>
					<div v-if="$isMobile.any" class="flex justify-center px-6 py-4 w-full mb-11">
						<div class="shadow-xl rounded-xl bg-color-white pt-4 pb-7 px-4 w-[358px] h-auto">
							<p
								class="font-bold text-center text-2xl bg-gradient-to-br from-color-main to-color-main-business bg-clip-text text-transparent mb-4"
							>
								Deine Vorteile <br />bei hokify
							</p>
							<p class="flex items-center mb-2 space-x-3 px-2 text-color-text">
								<HokIcon name="icon:checkmark-gradient" /><span class="text-sm">
									Ein Profil, alle Jobs
								</span>
							</p>
							<p class="flex items-center mb-0 space-x-3 px-2 text-color-text">
								<HokIcon name="icon:checkmark-gradient" /><span class="text-sm"
									>Mobile Jobsuche ohne Anschreiben
								</span>
							</p>
						</div>
					</div>
					<div v-else class="container max-w-3xl">
						<div
							class="flex items-end justify-between w-full shadow-xl rounded-xl bg-color-white pt-6 px-8 mb-12"
						>
							<div class="pb-6">
								<p
									class="font-bold text-2xl bg-gradient-to-br from-color-main to-color-main-business bg-clip-text text-transparent mb-4"
								>
									Deine Vorteile <br />bei hokify
								</p>
								<p class="flex items-center mt-3 mb-2 space-x-3 text-color-text">
									<HokIcon name="icon:checkmark-gradient" :size="7" /><span>
										Ein Profil, alle Jobs.
									</span>
								</p>
								<p class="flex items-center mb-0 space-x-3 text-color-text">
									<HokIcon name="icon:checkmark-gradient" :size="7" /><span
										>Mobile Jobsuche ohne Anschreiben.
									</span>
								</p>
							</div>
							<NuxtImg
								class="w-[288px] h-[162px] aspect-16/9"
								alt=""
								src="/shared-components-nuxt3/images/jobdetail-usp-desktop.png"
							/>
						</div>
					</div>
					<div
						v-if="jobRegion === 'at'"
						id="grossNetCalculator"
						ref="grossNetCalculatorRef"
						class="mx-5 pb-5 mb-5 border-b border-color-grey-light"
					>
						<div class="flex justify-between cursor-pointer" @click="showGnc = !showGnc">
							<h4 class="text-color-main text-xl">Jetzt Netto Gehalt herausfinden</h4>
							<div class="flex items-center">
								<HokIcon
									ref="arrow-down"
									name="icon:arrow-down"
									:size="$isMobile.any ? 4 : 6"
									:class="{ 'rotate-180 ': showGnc }"
									class="transition-transform duration-3000 bg-transparent"
								/>
							</div>
						</div>
						<TransitionExpand>
							<div v-if="showGnc" :class="{ '-mx-5': $isMobile.any }">
								<LazyGrossNetCalculator
									:is-pwa="isPwa"
									:gnc-calculation="gncCalculation"
									:job-fields="jobFields"
									:job-id="job._id"
									:remuneration="remuneration"
									:period="period"
									v-bind="$attrs"
									@go-to-jobsearch="$emit('go-to-jobsearch')"
									@update-remuneration="updateRemuneration($event)"
									@update-period="updatePeriod($event)"
								/>
							</div>
						</TransitionExpand>
					</div>
					<!--Map-->
					<div
						v-if="job.staticMapImageUrl"
						class="mx-5 pb-4 mb-10 border-b border-color-grey-light"
					>
						<div class="flex justify-between cursor-pointer" @click="revealMap">
							<h4 class="text-color-main text-xl">Anfahrt zum Arbeitgeber</h4>
							<HokIcon
								ref="arrow-down"
								name="icon:arrow-down"
								:size="$isMobile.any ? 4 : 6"
								:class="{ 'rotate-180 ': showMap }"
								class="transition-transform duration-3000 bg-transparent"
							/>
						</div>
						<LazyHokMarkerMap
							v-if="showMap"
							map-key="jobdetail"
							:country="topLevelDomain"
							:locations="locations"
							:initial-center="{
								lat: job.location.latitude,
								lng: job.location.longitude
							}"
							:height="384"
							:zoom="15"
							:auto-zoom="false"
							class="w-full md:w-3/4 lg:w-full mb-5"
						/>
					</div>
				</div>

				<div class="mx-5 mb-5">
					<div class="text-color-grey text-sm mt-3">
						<p class="mb-1">Job-ID.: {{ job.jobNr }}</p>
						<div class="mb-4">
							<span v-if="job.internal && job.internal.publishedDate" class="text-sm">
								Zuletzt aktualisiert {{ fromNow(job.internal.publishedDate) }}
								<meta itemprop="datePosted" :content="job.internal.publishedDate.toString()" />
							</span>
							<meta
								v-if="additionalSettings && additionalSettings.noIndexAfter"
								itemprop="validThrough"
								:content="additionalSettings.noIndexAfter.toString()"
							/>
						</div>
						<h4 class="text-color-text">Jobkategorien:</h4>
						<div class="flex flex-wrap mb-3">
							<div
								v-for="field in job.fields"
								:key="field._id"
								class="mb-3 text-color-text tracking-wide bg-color-grey-lightest px-3 py-1 rounded-full mr-2"
								:class="{ 'cursor-pointer': !isPwa }"
								@click="!isPwa ? newSearchViaTag(field.name) : undefined"
							>
								<span>
									{{ field.name }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="similarJobs && similarJobs.length > 0" class="mx-5 pb-5 mb-5">
					<h4 class="text-color-text text-xl mb-5 pt-5">Weitere interessante Jobs:</h4>
					<LazyJobList
						:jobs="similarJobs"
						:show-description="!$isMobile.phone"
						:similar-job-list="true"
						:saved-matches="savedMatches"
						monochrome
						:is-user-app="isPwa"
						v-bind="$attrs"
					/>
				</div>
				<LazyCallToAction
					v-if="$isMobile.phone && ((!isPwa && job.fields) || (isPwa && loggedIn))"
					styling="user-banner"
					:enable-link="false"
					class="mb-5"
					@cta-click="$emit('setup-jobalarm')"
				>
					<template v-if="!isPwa" #headline
						>Erhalte neue {{ job.fields[0].name }} Jobs per E-Mail!
					</template>
					<template v-else #headline
						>Aktiviere jetzt den Jobalarm für deine aktuelle Suche!
					</template>
					<template #button> Jobalarm aktivieren </template>
				</LazyCallToAction>
			</section>
		</div>
		<LazySharingModal
			:show-sharing-button="false"
			:sharing-info="sharingInfo"
			website-mode
			@sharing-modal-tracking="$emit('send-job-completed', $event)"
		/>
	</div>
</template>

<script lang="ts">
import type {
	GNCPeriod,
	IAPIGrossNetCalculation,
	IAPIJobForUser,
	IAPIJobPreviewForUser,
	IAPIJobQuestion,
	IAPILocation,
	IAPIMatchForJobSeekerListEntry,
	IAPIOwnerSnippet,
	IAPIPageJobDetail
} from '@hokify/common';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import LazySharingModal from './SharingModal.vue';
import { getJobSharingInfo, ISharingInfo } from '../helpers/sharing-modal';
import type { IRecentJobsearch } from '../types/recentJobsearch';
import { getTextForSearches } from '../helpers/jobsearch';
import { fromNow } from '../helpers/datehelpers/from-now';
import { lsTest } from '../helpers/localstorage';
import { truncateFct } from '../helpers/truncate';
import BeautifyText from './BeautifyText.vue';
import JobFacts from './JobFacts.vue';
import JobHeaderImage from './JobHeaderImage.vue';
import HokLink from './HokLink.vue';
import TransitionExpand from './TransitionExpand.vue';
import LazyHokMarkerMap from './HokMarkerMap.vue';
import LazyJobRequiredFields from './JobRequiredFields.vue';
import LazyJobContactPerson from './JobContactPerson.vue';
import LazyJobList from './JobList.vue';
import LazyCallToAction from './CallToAction.vue';
import LazyGrossNetCalculator from './GrossNetCalculator.vue';

function isHTMLElement(element: any): element is HTMLElement {
	return element && element instanceof HTMLElement;
}

type MapState = 'image' | 'map';
export default defineComponent({
	name: 'JobDetailNew',
	emits: [
		'save-job',
		'delete-job',
		'acts-invite',
		'go-to-jobsearch',
		'setup-jobalarm',
		'send-job-completed',
		'show-map-dropdown',
		'show-map-image',
		'send-job',
		'new-search-via-tag'
	],
	methods: {
		fromNow,
		getTextForSearches,
		truncateFct,
		registerIntersectionObserver() {
			const container = document.querySelector('#overflow-container');
			this.observer = new IntersectionObserver(this.isVisible, { root: container });
			this.observeElements();
		},
		observeElements() {
			const element = document.querySelector('#jobdetail-header-desktop');
			if (element) {
				this.observer?.observe(element);
			}
		},
		isVisible(entries) {
			this.showBar = !entries.some(entry => entry.isIntersecting);
		},
		handleClickContact() {
			if (this.job?.activeSourcingConversationId && this.isInContactWithCompany) {
				window.location.href = `/match/${this.job.activeSourcingConversationId}`;
			} else {
				this.$emit('acts-invite', 'accepted');
			}
		},
		setMapState(state: MapState) {
			this.mapState = state;
			if (this.jobRegion === 'de') {
				this.$emit('show-map-dropdown');
			} else {
				this.$emit('show-map-image');
			}
		},
		revealMap() {
			if (this.mapState !== 'map') {
				this.setMapState('map');
			}
			this.showMap = !this.showMap;
		},
		scrollToGnc() {
			this.showGnc = true;
			const element = this.$refs.grossNetCalculatorRef;
			if (isHTMLElement(element)) {
				element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		},
		handleNoInterest() {
			if (this.hasRejectedContactRequest) {
				this.$snack.danger({
					text: 'Du hast bereits "kein Interesse" kommuniziert, kannst aber dennoch Kontakt aufnehmen.'
				});
			} else {
				this.$emit('acts-invite', 'declined');
			}
		},
		shareJob() {
			if (!this.isPwa) {
				this.sharingInfo = getJobSharingInfo(useRuntimeConfig().public.env, this.job);
				this.$modal.show('sharingmodal');
			}
			this.$emit('send-job', 'cta-button-right');
		},
		onJobChanged() {
			this.showGnc = false;
			this.showMap = false;
			this.mapState = 'image';
		},
		updateRemuneration(updatedRemuneration) {
			this.remuneration = updatedRemuneration;
		},
		updatePeriod(updatedPeriod) {
			this.period = updatedPeriod;
		},
		newSearchViaTag(jobField: string) {
			this.$emit('new-search-via-tag', jobField);
		}
	},
	components: {
		HokLink,
		BeautifyText,
		JobFacts,
		JobHeaderImage,
		TransitionExpand,
		LazySharingModal,
		LazyJobList,
		LazyJobContactPerson,
		LazyHokMarkerMap,
		LazyJobRequiredFields,
		LazyCallToAction,
		LazyGrossNetCalculator
	},
	data() {
		const sharingInfo: ISharingInfo = {
			salutation:
				'Hey!\n\nIch habe gerade ein interessantes Jobangebot gefunden. Wäre das etwas für dich?\n',
			link: '',
			jobName: '',
			companyName: '',
			details: '',
			targetObjId: '',
			targetType: 'job'
		};
		const observer = undefined as undefined | IntersectionObserver;
		const cordova = process.env.cordova as string | null;
		const recentJobSearches = [] as IRecentJobsearch[] | [];
		const remuneration = undefined as number | undefined;
		const period: GNCPeriod = 3;

		return {
			recentJobSearches,
			mapState: 'image',
			mode: 'sendjob',
			cordova,
			observer,
			showBar: false,
			showGnc: false,
			showMap: false,
			showUSP: false,
			revealFullText: false,
			sharingInfo,
			remuneration,
			period
		};
	},
	computed: {
		userImg(): string {
			if (this.userImg) {
				return this.userImage;
			}
			return '/shared-components-nuxt3/svgs/user-image-placeholder.svg';
		},
		topLevelDomain(): string {
			return this.$nuxt?.$userRootStore?.topLevelDomain || 'at';
		},
		jobFields(): { value: string }[] | undefined {
			return this.job?.fields.map(field => ({
				value: field.name
			}));
		},
		requiredQuestions(): IAPIJobQuestion[] | undefined {
			return this.job?.questions?.filter(q => q?.required === true);
		},
		optionalQuestions(): IAPIJobQuestion[] | undefined {
			return this.job?.questions?.filter(q => q?.required === false);
		},
		locations(): IAPILocation[] {
			return [
				{
					latitude: this.job.location?.latitude,
					longitude: this.job.location?.longitude,
					name: this.job.location?.name
				}
			];
		},
		contactPerson(): IAPIOwnerSnippet | undefined {
			return this.job?.owners?.[0];
		},
		contactPersonText(): string {
			return this.contactPerson?.general?.gender === 'female'
				? 'Deine Ansprechpartnerin'
				: 'Dein Ansprechpartner';
		},
		applyTime(): string {
			let totalquestioncount = 0;
			if (this.job.questions?.length) {
				totalquestioncount += this.job.questions.length;
			}
			if (this.job.requiredFields?.length > 0) {
				totalquestioncount += this.job.requiredFields.length;
			}
			return Math.round((totalquestioncount * 20) / 60) <= 1
				? 'innerhalb einer Minute'
				: `ca. ${Math.round((totalquestioncount * 20) / 60)} Minuten`;
		},
		hasRequiredFields(): boolean {
			return this.job?.requiredFields?.some(
				rqf => (rqf as any).type === 'EDUCATIONLEVEL' || (rqf as any).type === 'JOBLEVEL'
			);
		},
		requiredYesNoQuestions(): any[] {
			return (
				this.job?.requiredFields?.filter(
					rqf => (rqf as any).type === 'YES_NO' && (rqf as any).required === true
				) || []
			);
		},
		optionalYesNoQuestions(): any[] {
			return (
				this.job?.requiredFields?.filter(
					rqf => (rqf as any).type === 'YES_NO' && (rqf as any).required !== true
				) || []
			);
		},
		linkToApply(): string {
			return `/apply/${this.job.jobNr}`;
		},
		jobRegion() {
			return this.job?.internal?.region;
		},
		addressRegion(): string | undefined {
			return this.job.location?.county?.toUpperCase() || this.job.internal?.region?.toUpperCase();
		},
		selectedCandidate(): boolean {
			return !!this.job?.selectedCandidate;
		},
		activeSourcingMode(): boolean {
			return !!(this.loggedIn && this.job?.activeSourcingContactStatus === 'activesourced');
		},
		isInContactWithCompany(): boolean {
			return !!(this.job?.activeSourcingContactStatus === 'incontact');
		},
		hasRejectedContactRequest(): boolean {
			return !!(this.job?.activeSourcingContactStatus === 'request-rejected');
		},
		personalCompanyText(): string {
			return this.job?.personalCompanyText || '';
		},
		companyLogo(): string {
			return this.job?.company?.logos?.small || '';
		},
		isATSJob(): boolean {
			return !!(this.job?.activeSourcingApplicationTarget === 'application');
		},
		showExtraFunctionality() {
			return (
				!this.job.activeSourcingContactStatus ||
				(this.job.activeSourcingContactStatus && this.isMatchView)
			);
		},
		popupHandler() {
			return this.$refs.popupHandler as any;
		}
	},
	mounted() {
		if (lsTest()) {
			const storage = localStorage.getItem('recent-jobsearches');
			if (typeof storage === 'string') {
				this.recentJobSearches = JSON.parse(storage);
			}
		}
		this.registerIntersectionObserver();
	},
	props: {
		job: { type: Object as PropType<IAPIJobForUser>, required: true },
		additionalJobData: {
			type: Object as PropType<IAPIPageJobDetail['additionalJobData']>,
			default: () => {}
		},
		additionalSettings: {
			type: Object as PropType<IAPIPageJobDetail['additionalSettings']>,
			default: () => {}
		},
		jobOverviewLps: {
			type: Array as PropType<IAPIPageJobDetail['additionalSettings'][]>,
			default: () => []
		},
		similarJobs: { type: Array as PropType<IAPIJobPreviewForUser[]>, default: () => [] },
		jobField: { type: String, required: false },
		jobIsSaved: { type: Boolean, default: false },
		singleJobView: { type: Boolean, default: false },
		showApplyButton: { type: Boolean, default: true },
		showSaveButton: { type: Boolean, default: false },
		showDeleteButton: { type: Boolean, default: false },
		isMatchView: { type: Boolean, default: false },
		isPwa: { type: Boolean, default: false },
		loggedIn: { type: Boolean, default: false },
		savedMatches: { type: Array as PropType<IAPIMatchForJobSeekerListEntry[]>, required: false },
		gncCalculation: { type: Object as PropType<IAPIGrossNetCalculation>, required: false },
		gncClosed: { type: Boolean, required: false },
		userImage: { type: String, required: false },
		region: { type: String, required: false },
		fromRoute: { type: String as PropType<'startpage' | 'gnc' | 'default'>, default: 'default' },
		showEducation: { type: Boolean, default: false }
	},
	watch: {
		job: [
			{
				handler: 'onJobChanged'
			}
		]
	}
});
</script>
<style lang="scss" scoped>
.custom-gradient {
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 1) 52%,
		rgba(255, 255, 255, 0) 100%
	);
}

// need to overwrite hokbutton classes via css specificity
#no-interest-desktop {
	&.not-allowed {
		@apply cursor-not-allowed text-color-grey;
	}
}
</style>
