<template>
	<form data-type="text" class="flex flex-col" @submit.stop.prevent="sendFeedback">
		<p class="font-light" v-html="question"></p>
		<div class="flex justify-around mb-3 px-3">
			<HokIcon
				v-for="smiley in smileys"
				:key="smiley.value"
				:size="12"
				:name="smiley.name"
				color="grey-medium"
				class="hover:text-color-main-business"
				:class="{ 'text-color-main-business': rating === smiley.value }"
				pointer
				@click="rating = smiley.value"
			/>
		</div>
		<HokTextArea
			:id="`feedback-${targetData ? targetData.targetType : 'general-feedback'}`"
			v-model="feedback"
			type="text"
			fullwidth="mobile"
			box-height="medium"
			disable-resize
			class="pb-3 flex-1"
			is-company-page
		>
			Erzählen Sie uns mehr...
		</HokTextArea>
		<HokButton class="block ml-auto" color="business" :disabled="!feedback && !rating" submit
			>Feedback senden</HokButton
		>
	</form>
</template>

<script lang="ts">
import type { APIObjectType, APITypeObjectId } from '@hokify/common';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import HokTextArea from './HokTextArea.vue';

export interface IFeedbackFormTargetData {
	targetObjId: APITypeObjectId<APIObjectType.Job> | APITypeObjectId<APIObjectType.Company>;
	targetType: 'job' | 'company';
	type?: 'share-company' | 'share-job' | 'active-sourcing';
}

export default defineComponent({
	name: 'FeedbackForm',
	components: {
		HokTextArea
	},
	data() {
		const rating = 0;

		return {
			feedback: '',
			rating,
			smileys: [
				{ name: 'icon:smiley-bad', value: 1 },
				{ name: 'icon:smiley-sad', value: 2 },
				{ name: 'icon:smiley-indifferent', value: 3 },
				{ name: 'icon:smiley-smile', value: 4 },
				{ name: 'icon:smiley-happy', value: 5 }
			]
		};
	},
	methods: {
		async sendFeedback() {
			const feedbackForm = {
				targetObjId: this.targetData?.targetObjId,
				targetType: this.targetData?.targetType || 'general-feedback',
				rating: this.rating ?? undefined,
				answer: this.feedback,
				type: this.targetData?.type || 'general-feedback'
			};
			this.$emit('send-feedback', feedbackForm);
			this.feedback = '';
			this.rating = 0;
		}
	},
	props: {
		question: { type: String, required: true, default: '' },
		targetData: { type: Object as PropType<IFeedbackFormTargetData>, default: () => {} }
	}
});
</script>
